<template>
  <div class="swiper-lightbox">
    <Dialog
      :open="!!lightboxContent && lightboxContent.lightboxId === lightboxId"
      @close="!lightboxContent"
    >
      <DialogPanel
        v-if="lightboxContent && lightboxContent.lightboxId === lightboxId"
        class="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-black text-white"
      >
        <video
          v-if="lightboxContent.type === 'video'"
          ref="videoFullRef"
          class="focus:outline-none focus-visible:outline-current"
          playsinline
          disablepictureinpicture
          controls
          width="1920"
          height="1080"
        >
          <source :src="lightboxContent.src" type="video/mp4" />
        </video>
        <img
          v-else-if="lightboxContent.type === 'image'"
          :src="lightboxContent.src"
          alt=""
        />
        <button
          title="Schließen"
          class="absolute right-vw-4-to-8 top-vw-5-to-10 aspect-square rounded-full bg-dark-blue p-vw-3-to-4 focus:outline-none focus-visible:outline-current"
          @click="$emit('close-lightbox')"
        >
          <AppHeaderBurgerIcon :open="true" />
        </button>
      </DialogPanel>
    </Dialog>
  </div>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel } from '@headlessui/vue'
import type { LightboxContent } from '~/types/generated'

defineProps<{
  lightboxContent: LightboxContent | null
  lightboxId: number
}>()
</script>
